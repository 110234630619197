import { ProductsValue } from './products.value';
import { BaseParams, BaseParamsInterface } from '@app/store/models';

interface ParamsType extends BaseParamsInterface {
    item_category_code?: string;
    item_new_in?: string;
    item_promo?: string;
    search?: string;
    [key: string]: string | number | undefined;
}

class ProductsValueDto extends BaseParams {
    private _params: ParamsType;

    constructor(dto: ProductsValue) {
        super(dto);

        this._params = { ...this.baseParams };

        if (dto.category) {
            this._params['item_category_code'] = dto.category;
        }

        if (dto.customCategory) {
            this._params['item_category_code'] = dto.customCategory;
        }

        if (dto.discount === 'novelties') {
            this._params['item_new_in'] = 'true';
        }

        if (dto.discount === 'promotions') {
            this._params['item_promo'] = 'true';
        }

        if (dto.search) {
            this._params['search'] = dto.search;
        }

        Object.keys(dto).forEach((key) => {
            if (key.startsWith('category_filters')) {
                this._params[key] = dto[key];
            }
        });
    }

    get params(): Record<string, string> {
        return Object.fromEntries(
            Object.entries(this._params).filter((entry): entry is [key: string, value: string] => entry[1] !== undefined && entry[1] !== null),
        );
    }
}

export { ProductsValueDto };
